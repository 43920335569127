<template>
  <div class="home">
<a-breadcrumb>
    	<a-breadcrumb-item href="">
    		<a-icon type="home" />
    	</a-breadcrumb-item>
    	<a-breadcrumb-item href="">
    		<a-icon type="desktop" />
    		<span>监控功能</span>
    	</a-breadcrumb-item>
    	<a-breadcrumb-item> 设备信息统计 </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="search-list-box">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item :label="`日期查询：`">
             <a-select
                style="width: 100%"
                placeholder="请选择"
				v-model="searchData.type"
              >
				<a-select-option :value="0"> 全部 </a-select-option>
                <a-select-option :value="1"> 昨天 </a-select-option>
				<a-select-option :value="2"> 今天 </a-select-option>
				<a-select-option :value="3"> 本周 </a-select-option>
				<a-select-option :value="4"> 本月 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item :label="`选择日期：`">
               <a-range-picker :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          
          <a-col
            :span="6"
            :style="{
              textAlign: 'left',
              paddingLeft: '25px',
              marginTop: '6px',
            }"
          >
            <a-button type="primary" @click="getList"> 查询 </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="search-result-list">
        <div class="table-header-box">
          <div class="left">
            <h3 class="title">设备信息统计列表</h3>
          </div>
          <div class="right">
            <a-button type="primary" class="marginr20" @click="dow">
            							导出Excel
            						</a-button>
          </div>
        </div>
        <a-table
       :columns="columns" @change="handleTableChange"
        :pagination="{total: total_page * 10}" :data-source="list" :scroll="{ x: 1200 }" rowKey="tran_id" size="small"
        >
         
        </a-table>
      </div>
    </div>

  
  </div>
</template>

<script>
import moment from 'moment';

const columns = [
	{
		title: "网点名称",
		dataIndex: "node_name",
		key: "1235"
	},
	{
		title: "所属银行",
		dataIndex: "bank_name",
		key: "4123"
	},
	 { title: "加钞总金额", dataIndex: "add_amount", key: "32",align: 'center' },
	 { title: "清钞总金额", dataIndex: "clean_amount", key: "33",align: 'center' },
	{ title: "硬币回收量", dataIndex: "coin_in_amount", key: "34",align: 'center' },
	 { title: "硬币投放量", dataIndex: "coin_out_amount", key: "1",align: 'center' },
	 
	
  { title: "开机率", dataIndex: "run_rate", key: "23",align: 'center' },
  { title: "故障次数", dataIndex: "fail_num", key: "213",align: 'center' },
];
import http from '../http'
export default {
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      columns,
	  searchData: {
	  	page: 1,
	  	type: 0,
	  	start_date: '',
	  	end_date: ''
	  },	
	  list: [],
	  total_page: 0,
	  
	  // 表格选择的数据, 存储的是id
	  selectedRowKeys: [],
	  
	  pickerKey: Math.random()
    };
  },
  computed: {
    count() {
      return this.expand ? 11 : 7;
    },
  },
  updated() {
    console.log("updated");
  },
  created () {
	  this.getList()
  },
  methods: {
      moment,
	   disabledDate(current) {
	        return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
	      //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
	   },
	  // 表格分页
	  handleTableChange(pagination, filters, sorter) {
	  	console.log(pagination, filters, sorter)
	  	this.searchData.page = pagination.current
	  	this.getList();
	  },
	  dow () {
	  	// window.open()
	  	http({
	  		method: 'post',
	  		url: '/api.transaction.export.device/',
	  		data: {
	  			bank_id: this.searchData.bank_id || 0,
	  			node_id: this.searchData.node_id || 0,
	  		}
	  	}).then((res) => {
	  		if (res.code == 200) {
	  			window.open(res.data)
	  		} else {
	  			this.$message.error(res.message);
	  		}
	  	})
	  },
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
    },
	
	// 表格选择
	onSelectChange(selectedRowKeys) {
		console.log('selectedRowKeys changed: ', selectedRowKeys);
		this.selectedRowKeys = selectedRowKeys;
	},

   handleReset() {
	   this.pickerKey = Math.random()
     this.searchData = {
     	page: 1,
     	type: 0,
     	start_date: '',
     	end_date: ''
     }
     this.getList()
   },
	
	// 日期解析
	handleChangeDate(date) {
		this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
		this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
	},
	
	// 列表
	getList() {
		http({
			method: 'post',
			url: '/api.transaction.dcount/',
			data: {
				...this.searchData
			}
		}).then((res) => {
			this.list = res.data.list
			this.total_page = res.data.total_page
		})
	},
  },
};
</script>
<style>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.search-list-box .ant-form {
  max-width: none;
}
.search-list-box .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>
